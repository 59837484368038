.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem;
}

.reports-table td {
    cursor: pointer;
}

.reports-table td:hover {
    background-color: #E7EDF3;
}

.reports-table td.link {
    cursor: default !important;
}

.reports-table td.link:hover {
    background-color: inherit;
}

.card-footer button {
    margin-right: 10px;
}

.card-footer .paging-group button {
    margin-right: 0;
}

.table tfoot td {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 120% !important;
}

.table tfoot tr:first-child td {
    border-top: 3px solid #F2F4F4;
}

table.small td {
    padding: 5px 5px;
}

table.small .form-group {
    margin: 0 !important;
}


.table-striped tbody tr:nth-of-type(odd).bg-green-100 {
    background-color: rgba(209, 250, 229, 0.8);
}

.dropdown-divider {
    height: 1px;
    margin: 5px 0px;
    background-color: #F2F4F4;
}

body {
    /* background-color: #FFF !important; */
}

.page-bg {
    background-color: #F2F4F4;
}



.quill .ql-toolbar:first-child {
    display: none;
}

.field-date-small {
    width: 100px !important;
    min-width: 100px !important;
    max-width: 100px !important;
}